import { Box, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { CarResult, getCarPrice } from '@japieglobal/shared/src/types/car-types';
import { AppSearchParamKeys, DisplayHints, useAppSearchParams } from '@japieglobal/shared/src/components';
import { ResultBrandModel } from './result-cells/result-brand-model-cell';
import { ResultCarMessageShareCell } from './result-cells/result-car-message-share';
import { ResultCopyCarIdentifier } from './result-cells/result-copy-car-identifier';
import { ResultPlusButtonCell } from './result-cells/result-plus-button-cell';
import { ResultPriceCell } from './result-cells/result-price-cell';
import { ResultPriceProposalLockCell } from './result-cells/result-price-proposal-lock';
import { ResultTableInformationCell } from './result-cells/result-table-information-cell/result-table-information-cell';
import { CarTableCell } from './result-table-styles';
import { ResultTrendCell } from './result-cells/result-trend-cell';
import { ResultWindowButtonCell } from './result-cells/result-window-button-cell';
import { settings } from '@japieglobal/shared/src/settings';
import { CarStatus, TopDealer } from '@japieglobal/shared/src/types';
import { ResultTaxationButton } from './result-cells/result-taxation-button';
import { hasPermission, noop } from '@japieglobal/shared/src/utils';
import { ResultTableImageCell } from './result-cells/result-table-image-cell';
import { ellipsify } from '../../utils/ellipsify';
import { ResultTopDealers } from '../../taxation-and-monitoring-shared/result-top-dealers';
import { ResultPriceInformation } from './result-cells/result-price-information';
import { compareMemoProps } from '../../utils/compare-react-props';
import { ResultUrlToExternalCell } from './result-cells/result-url-to-external-cell';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { UserPermission } from '@japieglobal/shared/src/api';

const useStyles = makeStyles({
  rowNoBorder: {
    '& td': {
      border: '0',
    },
  },
});

interface ResultTableRowProps {
  lt?: CarResult | undefined;
  car: CarResult;
  reloadCarList: (id: number) => void;
  closeDialog?: () => void;
  windowView?: boolean;
  isCompetitorIncluded?: boolean;
  topDealers?: TopDealer[];
}

export const ResultTableRow = React.memo(
  ({ car, isCompetitorIncluded, reloadCarList, windowView, closeDialog = noop, topDealers }: ResultTableRowProps) => {
    const classes = useStyles();
    const [price, setPrice] = useState(getCarPrice(car));
    const { user } = useContext(UserContext);
    const [{ click_dealer }, setSearchParams] = useAppSearchParams([AppSearchParamKeys.CLICK_DEALER]);
    const setCompetitorCar = useCallback(() => setSearchParams({ window_car_id: car.id }), [car, setSearchParams]);

    const showLicensePlate = useMemo(
      () => settings().country == 'nl' && (car.license_plate != 'NB' || (!car.vin && !car.reference_code)),
      [car],
    );

    const showTaxationButton = useMemo(() => !windowView && car.status == CarStatus.SOLD, [car.status, windowView]);

    const showWindowButton = useMemo(() => !windowView && car.status == CarStatus.STOCK, [car.status, windowView]);

    const tableRowContent = useMemo(
      () => (
        <>
          <ResultTableImageCell car={car} windowView={windowView} />
          <ResultBrandModel car={car} windowView={windowView} />
          <ResultTableInformationCell car={car} />
          <CarTableCell>
            <p title={car.location_name}>{ellipsify(car.location_name, 30)}</p>
          </CarTableCell>
          <ResultCopyCarIdentifier
            carIdentifier={(showLicensePlate ? car.license_plate : car.reference_code || car.vin) || ''}
            replaceDashes={showLicensePlate}
          />
          {showWindowButton && (
            <ResultPlusButtonCell
              setCompetitorCar={setCompetitorCar}
              disabled={!!car.sold?.since && car.sold?.since > 0}
              includesBanned={car.includes_banned}
            />
          )}
          {showTaxationButton && (
            <CarTableCell>
              <ResultTaxationButton car={car} />
            </CarTableCell>
          )}
          <ResultUrlToExternalCell car={car} />
          <ResultPriceInformation windowView={windowView} car={car} />
          <ResultTrendCell car={car} />
          {!windowView && <ResultWindowButtonCell car={car} />}
          <ResultPriceCell
            price={price}
            setPrice={setPrice}
            car={car}
            closeDialog={closeDialog}
            reloadCarList={reloadCarList}
            competitorView={windowView}
          />
          <ResultPriceProposalLockCell
            closeDialog={closeDialog}
            targetPrice={price}
            car={car}
            reloadCarList={reloadCarList}
            isCompetitorView={isCompetitorIncluded}
          />
          {<ResultCarMessageShareCell windowView={windowView} car={car} />}
          {isCompetitorIncluded && (topDealers || click_dealer) && (
            <Box mt={1}>
              <ResultTopDealers dealers={topDealers} selected={click_dealer} />
            </Box>
          )}
          {isCompetitorIncluded && hasPermission(user, UserPermission.MONITORING_TAXATION_BUTTON) && (
            <ResultTaxationButton car={car} />
          )}
          <DisplayHints />
        </>
      ),
      [
        car,
        isCompetitorIncluded,
        topDealers,
        windowView,
        click_dealer,
        price,
        showLicensePlate,
        showTaxationButton,
        showWindowButton,
        user,
        closeDialog,
        reloadCarList,
        setCompetitorCar,
      ],
    );

    return useMemo(
      () => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TableRow name="result-table-row" key={car.id} className={windowView ? classes.rowNoBorder : undefined}>
          {tableRowContent}
        </TableRow>
      ),
      [car?.id, classes.rowNoBorder, tableRowContent, windowView],
    );
  },
  compareMemoProps(['car']),
);
